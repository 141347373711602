<div>
    <!-- * HEADER FILTER PAGE -->
  <div 
      class="container-header-page" 
      [class.bg-transition]="isDivVisible" 
      #headerFilter
    >
      <div class="header-img-filter col-1">
        <img
          [src]="isDivVisible ? '../../../../assets/images/claro_logo_70.png' : '../../../../assets/images/claro_logo_50.png'"
          (click)="openHeaderFilter()"
          alt="logo da claro"
        >
      </div>

      <div 
        class="container-header-filters col-10" 
        [@slideInOut]="isDivVisible ? 'in' : 'out'"
      >
        <!-- * TOP FILTER -->
        <div class="container-filters col-12 p-0">
          
          <div class="col-lg-4 col-md-4">
            <app-data-picker-default
              [dates]="getRangeDate()"
              (event)="onChange($event)"
              [backgroundColor]="'#f9f9f9'" 
              [color]="'#333333'"
            >
            </app-data-picker-default>
          </div>

          <div class="col-2">              
            <app-select-default
              key="type"
              title="TIPO"
              [searchFilter]="true"          
              [dropdownList]="dropdownFilters.value?.types"
              (event)="onChange($event)"
            >
            </app-select-default>
          </div>

          <div class="col-5">
            <app-filter-default
              key="director"
              filterTitle="DIRETORIA"
              [icon]="icons.calendar"
              [dropdownList]="getButtonsFilterDefault(dropdownFilters.value?.directories)"
              (event)="onChange($event)"
            >
            </app-filter-default>
          </div>
        </div>
      </div> <!-- TOP FILTER -->
  </div> 

  <div class="container">
    <!-- Contêiner da Tabela -->
    <div class="table-container">
      <!-- Estado de carregamento -->
      <div *ngIf="loading" class="loading-container">
        <div class="loading-spinner"></div>
        <p>Carregando dados...</p>
      </div>
  
      <!-- Estado de erro -->
      <div *ngIf="error" class="error-container">
        <p class="error-message">Ocorreu um erro ao carregar os dados.</p>
        <button class="retry-button" (click)="retryFetchData()">Tentar novamente</button>
      </div>
        
      <!-- Tabela -->
      <app-smart-table
        *ngIf="!loading && !error"
        [data]="data | async"
        [validators]="recurrenceTransformStrategy.validators()"
        [paginable]="true"
        [searchable]="true"
        [sortable]="true"
        [importable]="true"
        [exportable]="true"
        [submitable]="true"
        [preview]="false"
        [permissions]="userPermissions"
        [showImportButton]="enabledImportButton"
        [showExportButton]="enabledExportButton"
        [currentPage]="currentPage | async"
        [totalPages]="totalPages | async"
        [itemsPerPage]="itemsPerPage"
        [importDataCallback]="openImportDataModal"
        [exportDataCallback]="exportData"
        (onPageChange)="handlePageChange($event)"
        (onSubmitChanges)="handleChanges($event)"
      >
      </app-smart-table>
    </div>
  </div>
  
</div>