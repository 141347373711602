import { Injectable } from '@angular/core';
import { SmartTableRow } from 'src/app/components/smart-table/smart-table.component';
import { ExcelTransformStrategy } from 'src/app/interfaces';
import { Validators } from 'src/app/interfaces/Validators';

@Injectable()
export class VerificationImageTransformStrategy implements ExcelTransformStrategy {

  // Configuração de campos
  private readonly fieldConfig = [
    {
      column: 'ID DA ATIVIDADE',
      key: 'ticketId',
      field: 'ticketId',
      defaultValue: '',
      requiredPermissionToUpdate: null,
      readonly: false,
      sortable: false,
      clickable: true
    },
    {
      column: 'VALIDAÇÃO',
      key: 'validation',
      field: 'validation',
      defaultValue: '',
      requiredPermissionToUpdate: null,
      transform: this.parseValidation,
      readonly: false,
      sortable: false
    },
    {
      column: 'QUANTIDADE',
      key: 'quantity',
      field: 'quantity',
      defaultValue: '',
      requiredPermissionToUpdate: null,
      validators: [Validators.isNumber],
      readonly: false,
      sortable: false
    },
    {
      column: 'F1',
      key: 'f1',
      field: 'f1',
      defaultValue: '',
      requiredPermissionToUpdate: null,
      transform: this.parseValidationindicator,
      indicator: true,
      sortable: false,
      clickable: true
    },
    {
      column: 'F2',
      key: 'f2',
      field: 'f2',
      defaultValue: '',
      requiredPermissionToUpdate: null,
      transform: this.parseValidationindicator,
      indicator: true,
      sortable: false,
      clickable: true
    },
    {
      column: 'F3',
      key: 'f3',
      field: 'f3',
      defaultValue: '',
      indicator: true,
      transform: this.parseValidationindicator,
      requiredPermissionToUpdate: null,
      sortable: false,
      clickable: true
    },
    {
      column: 'F4',
      key: 'f4',
      field: 'f4',
      defaultValue: '',
      indicator: true,
      transform: this.parseValidationindicator,
      requiredPermissionToUpdate: null,
      sortable: false,
      clickable: true
    },
    {
      column: 'F5',
      key: 'f5',
      field: 'f5',
      defaultValue: '',
      indicator: true,
      transform: this.parseValidationindicator,
      requiredPermissionToUpdate: null,
      sortable: false,
      clickable: true
    }
  ];

  // Método para obter validadores
  validators() {
    return this.fieldConfig.reduce((acc, field) => {
      acc[field.column] = field.validators || [];
      return acc;
    }, {});
  }

  // Método principal de transformação
  transform(data: any[]): SmartTableRow[] {
    return data.reduce((result: SmartTableRow[], row, rowIndex) => {
      const id = row['id'];
      const values = this.fieldConfig.map((field, colIndex) => ({
        cellId: rowIndex * 100 + colIndex + 1,
        column: field.column,
        field: field.field,
        value: this.transformFieldValue(row[field.key], field),
        requiredPermissionToUpdate: field.requiredPermissionToUpdate,
        indicator: field.indicator,
        readonly: field.readonly,
        sortable: field.sortable,
        clickable : field.clickable
      }));

      result.push({ id, values });

      return result;
    }, []);
  }

  // Transforma o valor de um campo com base na configuração
  private transformFieldValue(value: any, field: any): any {
    if (field.transform) {
      return field?.transform(value);
    }
    return value ?? field.defaultValue;
  }

  private parseValidation(value: boolean): string {
    return value ? "✔️" : "❌";
  }
  
  // Método auxiliar para parse de datas
  private parseValidationindicator(value: string): string[] {
    if (value !== null && typeof value === 'string') {
      return value.split(',');
    } 
    return [];
  }
}
