<div>

  <div class="modal-header">
    <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()">
      &times;
    </button>
  </div>

  <div class="container">
    <!-- Contêiner da Tabela -->
    <div class="table-container">
      <!-- Estado de carregamento -->
      <div *ngIf="loading" class="loading-container">
        <div class="loading-spinner"></div>
        <p>Carregando dados...</p>
      </div>
  
      <!-- Estado de erro -->
      <div *ngIf="error" class="error-container">
        <p class="error-message">Ocorreu um erro ao carregar os dados.</p>
      </div>
        
      <!-- Tabela -->
      <app-smart-table
        *ngIf="!loading && !error"
        [data]="data | async"
        [cards]="cards | async"
        [validators]="validators"
        [paginable]="true"
        [searchable]="true"
        [sortable]="true"
        [importable]="false"
        [exportable]="true"
        [submitable]="false"
        [preview]="false"
        [maxHeight]="14"
        [currentPage]="currentPage | async"
        [totalPages]="totalPages | async"
        [itemsPerPage]="itemsPerPage"
        [openModal]="openTicketPopup"
        [exportDataCallback]="exportData"
        (onPageChange)="handlePageChange($event)"
      >
      </app-smart-table>
    </div>
  </div>
</div>