import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SmartTableRow } from '../smart-table/smart-table.component';
import { Permission } from 'src/app/interfaces';
import { ResponseValidator } from 'src/app/interfaces/Validators';
import { BehaviorSubject } from 'rxjs';
@Component({
  selector: 'app-import-data-modal',
  templateUrl: './import-data-modal.component.html',
  styleUrls: ['import-data-modal.component.scss']
})
export class ImportDataModalComponent {
  
  @Input() title: string = 'Importar Arquivo de Dados'; // Título do modal
  @Input() instructions: string = 'Para importar os dados, faça o upload de um arquivo no formato .xlsx. Certifique-se de que o arquivo segue a mesma estrutura do modelo disponibilizado abaixo.';
  @Input() resultTitle: string = 'Resultados da Importação'; // Título da seção de resultados
  @Input() results: { label: string; value: any }[] | null = null; // Resultados genéricos
  @Input() permissions: Permission[] = []; // Adaptar ao tipo de Permission
  @Input() downloadTemplateCallback!: () => void; // Callback para baixar o modelo
  @Input() processFileCallback!: (file: File) => Promise<{ data: SmartTableRow[], validators: Record<string, Array<(value: any) => ResponseValidator>>, results: any }>; // Callback para processar o arquivo
  @Input() handleChanges!: (results: { isPreview: boolean, data: SmartTableRow[] }) => void // Lida com as alterações pendentes

  data: SmartTableRow[] = [];
  paginatedData: BehaviorSubject<SmartTableRow[]> =  new BehaviorSubject([]);
  validators: Record<string, Array<(value: any) => ResponseValidator>> = {};
  selectedFile: File | null = null;
  loading: boolean = false;
  submitDisabled = new BehaviorSubject<boolean>(true);
  submitDisabled$ = this.submitDisabled.asObservable();

  currentPage = new BehaviorSubject<number>(1); // Página atual
  totalPages = new BehaviorSubject<number>(1); // Total de páginas
  itemsPerPage = 15 // Items por página

  constructor(
    public activeModal: NgbActiveModal
  ) {}

  downloadTemplate(): void {
    if (this.downloadTemplateCallback) {
      this.downloadTemplateCallback();
    }
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    const file = input?.files?.[0];
    if (file) {
      this.selectedFile = file;
      this.submitDisabled.next(false);
    }
  }
  
  async processFile(): Promise<void> {
    if (this.selectedFile && this.processFileCallback) {
      this.submitDisabled.next(true); // Desabilita o botão
      try {
        const results = await this.processFileCallback(this.selectedFile);
        this.data = results.data;
        this.validators = results.validators;
      } catch (error) {
        console.error('Erro ao processar o arquivo:', error);
      } finally {
        this.loading = false;
        this.submitDisabled.next(true); // Reabilita o botão
      }
    }
  }

  onSubmit(results: any): void {
    if (this.handleChanges) {
      this.handleChanges(results);
    }

    this.dismiss();
  }

  dismiss(): void {
    this.results = null;
    this.selectedFile = null;
    this.activeModal.close();
  }
}
