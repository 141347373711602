import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Permission } from 'src/app/interfaces';
import { ResponseValidator } from 'src/app/interfaces/Validators';

export interface SmartCellData {
  cellId: number,
  field: string,
  column: string;
  value: string;
  requiredPermissionToUpdate?: Permission;
  messages?: string;
  isHighlighted?: boolean;
  sortable?: boolean;
  readonly?: boolean;
  clickable?: boolean;
  tooltip?: boolean;
}

export interface SmartTableCard {
  description: string;
  value: any;
  icon: IconDefinition
}

export interface ModifiedCell {
  rowId: string | null;
  cell: SmartCellData;
}

export type SmartTableRow = {
  id: string | null,
  values: SmartCellData[]
}

@Component({
  selector: 'app-smart-table',
  templateUrl: './smart-table.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmartTableComponent implements OnInit {
  
  @Input() data: SmartTableRow[] = [];
  @Input() validators: Record<string, Array<(value: any) => ResponseValidator>> = {};

  // Controls
  @Input() paginable: boolean = false;
  @Input() searchable: boolean = false;
  @Input() sortable: boolean = false;
  @Input() importable: boolean = false;
  @Input() exportable: boolean = false;
  @Input() submitable: boolean = true
  @Input() preview: boolean = false;

  // Permissions
  @Input() permissions: Permission[] = [];
  // TODO: Talvez mudar o nome dessa variavel;

  @Input() showImportButton: boolean = true;
  @Input() showExportButton: boolean = true;

  @Input() currentPage: number = 1;
  @Input() itemsPerPage: number = 15;
  @Input() totalPages: number = 1;

  @Input() maxHeight: number = 12;

  @Input() cards: SmartTableCard[] = [];

  @Input() importDataCallback!: () => void;
  @Input() exportDataCallback!: ({ search, sortBy, sortDirection }) => void;
  @Input() openModal!: ({ rowId, value, field }: { rowId: string, field: string, value: any }) => void;
  
  @Output() onPageChange = new EventEmitter<{ page: number, size: number, search: string, isPreview: boolean, sortBy: string[], sortDirection: string[] }>();
  @Output() onSubmitChanges = new EventEmitter<{ isPreview: boolean, data: SmartTableRow[] }>(); // Emite alterações pendentes
  
  paginatedData: SmartTableRow[] = [];

  // TODO: Precisa virar Maps.
  invalidMap: Set<SmartCellData> = new Set();
  modifiedMap: Set<ModifiedCell> = new Set();
  invalidArray: Array<{ cell: SmartCellData, page: number }> = [];
  currentErrorIndex = 0;
  searchQuery: string = '';
  searchSubject: Subject<string> = new Subject<string>();
  sortCriteria: { field: string; direction: string }[] = []; // Lista de critérios de ordenação

  constructor() {}

  ngOnInit(): void {
    if (this.preview) {
      this.fetchData(this.currentPage);
      this.countErrors();
    }

    if (this.currentPage > this.totalPages) {
      this.currentPage = this.totalPages;
    }

    // Configura o debounce no Subject
    this.searchSubject
      .pipe(debounceTime(500)) // Ajuste o tempo conforme necessário
      .subscribe((query) => {
        const sortFields = this.sortCriteria.map((criteria) => criteria.field);
        const sortDirections = this.sortCriteria.map((criteria) => criteria.direction);
        this.onPageChange.emit({
          page: this.currentPage,
          size: this.itemsPerPage,
          isPreview: this.preview,
          search: query.trim().toLowerCase(),
          sortBy: sortFields,
          sortDirection: sortDirections
        });
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Sempre que os dados mudam rodo o highlight
    if (changes['data']) {
      this.applySearchHighlight();
    }
  }

  fetchData(page: number): void {
    if (page < 1 || page > this.totalPages) {
      return; // Validar página
    }

    // Integração com o serviço ou backend
    const sortFields = this.sortCriteria.map((criteria) => criteria.field);
    const sortDirections = this.sortCriteria.map((criteria) => criteria.direction);

    if (this.preview) {
      this.totalPages = Math.ceil(this.data.length / this.itemsPerPage);
      const startIndex = (page - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;  
      this.paginatedData = this.data.slice(startIndex, endIndex);
    } else {
      this.onPageChange.emit({
        page,
        size: this.itemsPerPage,
        isPreview: this.preview,
        search: this.searchQuery,
        sortBy: sortFields,
        sortDirection: sortDirections
      });
    }
  }

  // Navega para uma página específica
  goToPage(page: number): void {
    page = Number(page); // Garante que é um número

    if (page < 1 || page > this.totalPages) {
      return;
    }
    this.currentPage = (page > this.totalPages) ? this.totalPages : page;
    this.fetchData(this.currentPage);
  }
  
  onImport(): void {
    if (this.importDataCallback) {
      this.importDataCallback();
    }
  }

  onExport(): void {    const sortFields = this.sortCriteria.map((criteria) => criteria.field);
    const sortDirections = this.sortCriteria.map((criteria) => criteria.direction);
    if (this.exportDataCallback) {
      this.exportDataCallback({ search: this.searchQuery, sortBy: sortFields, sortDirection: sortDirections });
    }
  }

  onSearchInputChange(): void {
    this.searchSubject.next(this.searchQuery.trim().toLowerCase());
  }

  onSort(field: string): void {
    if (this.sortable) {
      const existingSort = this.sortCriteria.find((criteria) => criteria.field === field);
  
      if (existingSort) {
        if (existingSort.direction === 'ASC') {
          existingSort.direction = 'DESC';
        } else if (existingSort.direction === 'DESC') {
          this.sortCriteria = this.sortCriteria.filter((criteria) => criteria.field !== field);
        }
      } else {
        this.sortCriteria.push({ field, direction: 'ASC' });
      }
    
      this.fetchData(this.currentPage);
    }
  }  

  isCurrentSortField(field: string): boolean {
    return this.sortable && this.sortCriteria.some((criteria) => criteria.field === field);
  }

  // TODO: Passar destaque de colums por parâmetro.
  isHeaderhighlighted(column: string): boolean {
    return ['FATO', 'CAUSA', 'AÇÃO', 'PRAZO'].includes(column);
  }

  getSortDirection(field: string): string | null {
    // Retorna a direção atual para o campo
    const criteria = this.sortCriteria.find((criteria) => criteria.field === field);
    return criteria ? criteria.direction : null;
  }

  validateCell(cell: SmartCellData, rowIndex: number): boolean {
    const validators = this.validators[cell.column];
    const message: string[] = [];
  
    // Por favor não mudar a ordem.
    // Etapa 1
    if (validators) {
      for (const validator of validators) {
        const result = validator(cell.value);
        if (!result.isValid) {
          message.push(result.errorMessage);
        }
      }
    }

    // Etapa 2
    const isValid = message.length === 0;

    // Etapa 3
    if (cell.readonly && !cell.tooltip) {
      message.unshift('Este campo está bloqueado para edição.');
    }
    
    // Etapa 4
    if(cell.tooltip) {
      message.unshift(cell.value)
    }
    
    cell.messages = message.join('\n');

    // Epata 5
    if (!isValid) {
      const page = Math.floor(rowIndex / this.itemsPerPage) + 1;

      if (!this.invalidMap.has(cell)) {
        this.invalidArray.push({ cell, page });
      }
    }

    this.updateInvalidMap(cell, isValid)
    
    return isValid;
  }

  countErrors(): void {
    this.invalidArray = []; // Reinicializa o array
  
    this.data.forEach((row, rowIndex) => {
      row.values.forEach((cell) => {
        const isValid = this.validateCell(cell, rowIndex);
        this.updateInvalidMap(cell, isValid);
      });
    });

    this.currentErrorIndex = 0; // Reseta o índice ao contar erros novamente
  }
  
  // Atualiza o mapa de células inválidas
  private updateInvalidMap(cell: SmartCellData, isValid: boolean): void {
    if (!isValid) {
      this.invalidMap.add(cell); // Adiciona células inválidas
    } else if (this.invalidMap.has(cell)) {
      this.invalidMap.delete(cell); // Remove células válidas
    }
  }

  // TODO: Essa função poderia ser menos verbosa.
  private applySearchHighlight(): void {
    this.data.forEach((row) => {
      row.values.forEach((cell) => {
        const cellValue = cell.value != null ? cell.value.toString().toLowerCase() : '';
        if (this.searchQuery !== '' && cellValue.includes(this.searchQuery.toLowerCase())) {
          cell.isHighlighted = true; // Adiciona uma flag para destacar
        } else {
          cell.isHighlighted = false; // Remove a flag caso não corresponda
        }
      });
    });
  }

  navigateErrors(direction: 'prev' | 'next'): void {
    // Atualiza o índice antes de acessar o erro
    if (direction === 'prev' && this.currentErrorIndex > 0) {
      this.currentErrorIndex--;
    } else if (direction === 'next' && this.currentErrorIndex < this.invalidArray.length - 1) {
      this.currentErrorIndex++;
    }
  
    const currentError = this.invalidArray[this.currentErrorIndex];
  
    // Carrega a página correspondente ao erro, se necessário
    if (currentError && currentError.page !== this.currentPage) {
      this.fetchData(currentError.page);
      this.currentPage = currentError.page;
    }
  
    // Destaca a célula após garantir que a página está carregada
    if (currentError) {
      setTimeout(() => this.highlightCell(currentError.cell), 0);
    }
  }

  highlightCell(cell: SmartCellData): void {
    const element = document.querySelector(`[data-cell-id="${cell.column}-${cell.value}"]`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      element.classList.add('highlight');
      setTimeout(() => element.classList.remove('highlight'), 1500); // Remove o destaque após 1.5s
    }
  }
  
  // Verifica se o usuário tem permissão para editar a célula
  canEditCell(cell: SmartCellData): boolean {
    return this.permissions.includes(cell.requiredPermissionToUpdate);
  }

  markCellAsModified(cell: SmartCellData, rowId: string): void {
    const modifiedCell: ModifiedCell = { rowId, cell };
    const existingCell = Array.from(this.modifiedMap).find(
      (item) => item.rowId === rowId && item.cell.cellId === cell.cellId
    );

    if (!existingCell) {
      this.modifiedMap.add(modifiedCell);
    }
  }
  
  onInputUpdate(event: Event, cell: SmartCellData, rowId: string): void {
    const target = event.target as HTMLInputElement;
    cell.value = target.value; // Atualiza o valor da célula
    this.markCellAsModified(cell, rowId); // Passa o ID da linha
  }

  onCellClick(event: Event, cell: SmartCellData, rowId: string) {
    if (cell.clickable) {
      const target = event.target as HTMLInputElement;
      this.openModal({ rowId, value: target.value, field: cell.field });
    }
  }

  // TODO: Essa função poderia ser menos verbosa.
  submitChanges(): void {
    if (this.preview) {
      // Em pré-visualização, retorna todos os dados da tabela
      this.onSubmitChanges.emit({ isPreview: this.preview, data: this.data });
    } else {
      if (this.modifiedMap.size > 0) {
        // Agrupa as células modificadas pela chave rowId
        const groupedRows: SmartTableRow[] = Array
          .from(this.modifiedMap)
          .reduce((result, modifiedCell) => {
            const { rowId, cell } = modifiedCell;
            let row = result.find(r => r.id === rowId);
    
            if (!row) {
              row = { id: rowId, values: [] };
              result.push(row);
            }
    
            row.values.push(cell);
            return result;
        }, []);
    
        // Emite as células modificadas agrupadas
        this.onSubmitChanges.emit({ isPreview: this.preview, data: groupedRows });
  
        // Limpa o mapa de modificações
        this.modifiedMap.clear();
      }
    }
  }

  // TODO: As duas funções abaixo são bem parecidas e utilizam a mesma verificação.
  isModified(cell: SmartCellData, rowId: string): boolean {
    for (const modifiedCell of this.modifiedMap) {
      if (
        modifiedCell.rowId === rowId &&
        modifiedCell.cell.cellId === cell.cellId &&
        modifiedCell.cell.value !== ''
      ) {
        return true;
      }
    }
    return false;
  }
  
  getModified(cell: SmartCellData, rowId: string): string {
    for (const modifiedCell of this.modifiedMap) {
      if (
        modifiedCell.rowId === rowId &&
        modifiedCell.cell.cellId === cell.cellId &&
        modifiedCell.cell.value !== ''
      ) {
        return modifiedCell.cell.value;
      }
    }
    return cell.value;
  }
  
  trackByRow(index: number, row: any): any {
    return row.id || index;
  }
  
  trackByCell(index: number) {
    return index;
  }
}
