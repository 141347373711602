import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BehaviorSubject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { fadeInOutAnimation, slideInOutAnimation } from "src/app/animations/animations";
import { ImportDataModalComponent } from "src/app/components/import-data-modal/import-data-modal.component";
import { SmartTableRow } from "src/app/components/smart-table/smart-table.component";
import { ExcelProcessorService } from "src/app/services/excel-processor.service";
import { RecurrenceTransformStrategy } from "./strategy/RecurrenceTransformStrategy";
import { InputRecurrence, Recurrence, RecurrencesService } from "src/app/services/recurrences.service";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { RequestResult } from "src/app/interfaces/RequestContext";
import { FilterService, Namespace } from "src/app/services/filter.service";
import { Formatter } from "src/app/utils/formatter.util";
import { GenerateDate } from "src/app/utils/generateDate.util";
import { AuthService } from "src/app/services/auth.service";
import { getMapped } from "../../images/utils/mapped-values";
import { Permission } from "src/app/interfaces";
import { icons } from "src/app/utils/icons";

@Component({
  selector: 'app-recurrence',
  templateUrl: './recurrence.component.html',
  styleUrls: ['./recurrence.component.scss'],
  animations: [slideInOutAnimation, fadeInOutAnimation]
})
export class RecurrenceComponent implements OnInit {

  NAMESPACE: Namespace = 'recurrence'

  data: BehaviorSubject<SmartTableRow[]> = new BehaviorSubject([]);
  activeFilters: InputRecurrence = {};
  loading: boolean = true;
  error: boolean = false;

  instructions: string = `
    Para importar os dados:
      - Faça o upload de um arquivo no formato .xlsx.
      - Certifique-se de que o arquivo segue a mesma estrutura do modelo disponibilizado.
      - Registros sem designação preenchida não serão adicionados.
      - Preencha o volume antes de importar, pois é um campo apenas de leitura.
  `;
  
  currentPage = new BehaviorSubject<number>(1);
  totalPages = new BehaviorSubject<number>(1);
  itemsPerPage = 15
  dropdownFilters = new BehaviorSubject<{ types: string[]; directories: string[] }>({ types: [], directories: [] });
  icons=icons
  
  userPermissions: Permission[] = [Permission.RECURRENCE_UPDATE_ADMIN, Permission.RECURRENCE_UPDATE];
  userDirector: string;
  userId: number;
  isUserAdmin: boolean = false;

  enabledImportButton= false;
  enabledExportButton = false;
  isDivVisible: boolean = false;

  constructor(
    private filterService: FilterService,
    private authService: AuthService,
    private modalService: NgbModal,
    private excelProcessorService: ExcelProcessorService,
    private snackBar: MatSnackBar,
    public recurrenceService: RecurrencesService,
    public recurrenceTransformStrategy: RecurrenceTransformStrategy
  ) {
    const { startDate, endDate } = GenerateDate.getDates();

    const defaultActiveFilters: InputRecurrence = {
      startDate: Formatter.formatDataPickerDate(startDate),
      endDate: Formatter.formatDataPickerDate(endDate),
      type: '',
      director: '',
    }

    this.filterService.setDefaultFilters<InputRecurrence>({
      namespace: this.NAMESPACE,
      defaultFilters: defaultActiveFilters
    });

    this.filterService.getFiltersObservable<InputRecurrence>({
      namespace: this.NAMESPACE
    })
      .pipe(debounceTime(1000))
      .subscribe((activeFilters) => {
        this.activeFilters = activeFilters;
        this.fetchData(activeFilters, this.currentPage.value, this.itemsPerPage, null, null, null)
      });

    const user = this.authService.getCurrentUser();
      
    this.userPermissions = user.permissions;
    if (user.director !== null) {
      this.userDirector = getMapped({ key: 'groups', type: 'shortName', value: user.director.toUpperCase() ?? '' });
    } 
    this.userId = user.id;
    this.isUserAdmin = !this.userPermissions.includes(Permission.RECURRENCE_UPDATE_ADMIN);
  }

  ngOnInit(): void {
    this.enabledImportButton = this.userPermissions.includes(Permission.RECURRENCE_UPDATE_ADMIN);
    console.log(this.userPermissions.includes(Permission.RECURRENCE_EXPORT))
    this.enabledExportButton = this.userPermissions.includes(Permission.RECURRENCE_EXPORT);
  }

  handleChanges = (results: { isPreview: boolean, data: SmartTableRow[] }): void => {
    const { isPreview, data } = results;
  
    // TODO: Código repetitivo.
    if (isPreview) {
      const recurrences: Recurrence[] = data.map(row => this.mapRowToRecurrenceDto(row));
      this.recurrenceService.createRecurrences(recurrences).subscribe({
        next: (result) => {
          this.openPopUpResponse({
            status: 200,
            message: result.message,
            isError: false,
          });
          
          this.retryFetchData();
        },
        error: (error) => {
          this.openPopUpResponse({
            status: 404,
            message: error.message,
            isError: false,
          });
        },
      });
    } else {
      const recurrences: Recurrence[] = data.map(row => this.mapRowToRecurrenceDto(row));
      this.recurrenceService.updateRecurrences(recurrences).subscribe({
        next: (result) => {
          this.openPopUpResponse({
            status: 200,
            message: result.message,
            isError: false,
          });
        },
        error: (error) => {
          this.openPopUpResponse({
            status: 404,
            message: error.message,
            isError: true,
          });
        },
      });
    }
  };  
  
  openImportDataModal = () => {
    const modalRef = this.modalService.open(ImportDataModalComponent, { size: 'xl' });    
    modalRef.componentInstance.downloadTemplateCallback = this.downloadTemplateCallback;
    modalRef.componentInstance.processFileCallback = this.processFileCallback;
    modalRef.componentInstance.handleChanges = this.handleChanges;
    modalRef.componentInstance.instructions = this.instructions;
    modalRef.componentInstance.permissions = this.userPermissions;
  }

  handlePageChange(event: { page: number, size: number, search: string, isPreview: boolean, sortBy: string[], sortDirection: string[]  }): void {
    const page = event.page || 1;
    this.fetchData(this.activeFilters, page, event.size, event.search, event.sortBy, event.sortDirection);
  }

  fetchData(activeFilters: InputRecurrence, page: number, size: number, search: string, sortBy: string[], sortDirection: string[]) {
    if (this.isUserAdmin) {
      activeFilters.director = this.userDirector; 
    }
    
    this.recurrenceService
      .getRecurrences(activeFilters, page, size, search, sortBy, sortDirection)
      .subscribe({
        next: (response) => {
          const transformedData = this.recurrenceTransformStrategy.transform(response.data);
          this.data.next(transformedData);
          this.totalPages.next(response.totalPages);
          const filters = response.filters;

          if (this.isUserAdmin) {
            filters['directories'] = [this.userDirector];
          }

          this.dropdownFilters.next(filters);
          this.loading = false;
          this.error = false;
        },
        error: (err) => {
          this.loading = false;
          this.error = true;
        },
      });
  }

  downloadTemplateCallback(): void {
    const filename = 'MODELO_PAINEL_DE_REINCIDENCIA.xlsx'
    const fileUrl = `assets/excel/${filename}`;
    const anchor = document.createElement('a');
    anchor.href = fileUrl;
    anchor.download = 'modelo_painel_de_reincidencia.xlsx';
    anchor.click();
  }

  processFileCallback = async (file: File): Promise<{ result: {}, data: any[], validators: any }> => {
    try {
      const data = await this.excelProcessorService.processExcel(this.recurrenceTransformStrategy, file);
      return { result: [], data, validators: this.recurrenceTransformStrategy.validators() };
    } catch (error) {
      throw error;
    }
  };

  mapRowToRecurrenceDto = (row: SmartTableRow): Recurrence => {
    const valueMap = row.values.reduce((map, cell) => {
      map[cell.column] = cell.value || '';
      return map;
    }, {} as Record<string, string>);
  
    return {
      designation: valueMap['DESIGNAÇÃO'] || row.id,
      type: valueMap['TIPO'] || null,
      referenceDate: valueMap['MÊS REF'] || null,
      director: valueMap['DIRETORIA'] || null,
      volume: parseFloat(valueMap['VOL']) || null,
      incident: valueMap['FATO'] || null,
      cause: valueMap['CAUSA'] || null,
      action: valueMap['AÇÃO'] || null,
      deadline: valueMap['PRAZO PARA CONCLUSÃO DO PLANO'] || null,
    };
  };

  openPopUpResponse(requestResult: RequestResult) {
    const closeButtonMessage = 'Fechar';
    const styleResult = !requestResult.isError ? 'success-bar-container' : 'failure-bar-container';
    const snackBarSettings: MatSnackBarConfig = {
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: [styleResult]
    }
    this.snackBar.open(requestResult.message, closeButtonMessage, snackBarSettings);
  }

  exportData = ({ search, sortBy, sortDirection }) => {
    this.authService.getCurrentUser();
    this.recurrenceService
      .sendRecurrences(this.activeFilters, this.userId, search, sortBy, sortDirection)
      .subscribe({
        next: (response) => {
          this.openPopUpResponse({
            status: 200,
            message: response.message,
            isError: false,
          });
        },
        error: (err) => {
          this.openPopUpResponse({
            status: 404,
            message: err.message,
            isError: true,
          });
        },
      });
  }

  getButtonsFilterDefault(filters: string[]) {
    return filters.map((value) => ({ show: value, value }))
  }

  retryFetchData(): void {
    this.fetchData(
      this.activeFilters,
      this.currentPage.value,
      this.itemsPerPage,
      null,
      null,
      null
    );
  }

  openHeaderFilter() {
    this.isDivVisible = !this.isDivVisible;
  }

  getRangeDate() {
    const { startDate, endDate } = GenerateDate.getDateMinusDays(90);
    return {
      startDate,
      endDate,
    }
  }
  
  // Função de onChange simplificada, agora lidando apenas com activeFilters
  onChange(event: string) {
    const [key, values] = event.split(':');
    this.filterService.updateFilters<InputRecurrence>({
      namespace: this.NAMESPACE,
      key,
      values,
    });
  }
}