<div>

  <!-- * HEADER FILTER PAGE -->
  <div class="container-header-page" [class.bg-transition]="isDivVisible" #headerFilter>
    <div class="header-img-filter col-1">
      <img
        [src]="isDivVisible ? '../../../../assets/images/claro_logo_70.png' : '../../../../assets/images/claro_logo_50.png'"
        (click)="openHeaderFilter()" alt="logo da claro">
    </div>

    <div class="container-header-filters col-10" [@slideInOut]="isDivVisible ? 'in' : 'out'">
      <div class="container-filters col-12 p-0">

        <div class="col-lg-4 col-md-4">
          <app-data-picker-default [dates]="getRangeDate()" (event)="onChange($event)" [backgroundColor]="'#f9f9f9'"
            [color]="'#333333'">
          </app-data-picker-default>
        </div>

        <div class="col-2">
          <app-select-default key="causes" title="CAUSAS" [searchFilter]="true"
            [dropdownList]="dropdownFilters.value?.causes" [selectedItems]="selectedFilters.value?.causes"
            (event)="onChange($event)">
          </app-select-default>
        </div>

        <div class="col-2">
          <app-select-default key="regionals" title="REGIONAIS" [searchFilter]="true"
            [dropdownList]="dropdownFilters.value?.regionals" [selectedItems]="selectedFilters.value?.regionals"
            (event)="onChange($event)">
          </app-select-default>
        </div>

        <div class="col-2">
          <app-select-default key="directors" title="DIRETORIAS" [searchFilter]="true"
            [dropdownList]="dropdownFilters.value?.directors" [selectedItems]="selectedFilters.value?.directors"
            (event)="onChange($event)">
          </app-select-default>
        </div>

        <div class="col-2">
          <app-select-default key="states" title="ESTADOS" [searchFilter]="true"
            [dropdownList]="dropdownFilters.value?.states" [selectedItems]="selectedFilters.value?.states"
            (event)="onChange($event)">
          </app-select-default>
        </div>
      </div>

      <div class="container-filters col-12 p-0">

        <div class="col-2">
          <app-select-default key="clusters" title="CLUSTERS" [searchFilter]="true"
            [dropdownList]="dropdownFilters.value?.clusters" [selectedItems]="selectedFilters.value?.clusters"
            (event)="onChange($event)">
          </app-select-default>
        </div>

        <div class="col-2">
          <app-select-default key="subclusters" title="SUBCLUSTERS" [searchFilter]="true"
            [dropdownList]="dropdownFilters.value?.subclusters" [selectedItems]="selectedFilters.value?.subclusters"
            (event)="onChange($event)">
          </app-select-default>
        </div>

        <div class="col-2">
          <app-select-default key="cities" title="CIDADES" [searchFilter]="true"
            [dropdownList]="dropdownFilters.value?.cities" [selectedItems]="selectedFilters.value?.cities"
            (event)="onChange($event)">
          </app-select-default>
        </div>

      </div>
    </div>

    <div class="col-1" [@slideInOut]="isDivVisible ? 'in' : 'out'">
      <div class="col-2">
        <button class="clear-filters-button" (click)="cleanFilters()" matTooltip="Limpar filtros"
          aria-label="Limpar filtros">
          <i class="fa fa-trash"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="container">

    <div class="legends-container">
      <div *ngFor="let row of getLegendRows(this.legends.value)" class="legend-row">
        <div *ngFor="let legend of row" class="legend-item" (click)="toggleValidation(legend)"
          [class.active]="isActive(legend)">
          <span class="dot" [ngClass]="legend.value"></span>
          <span class="legend-description">{{ legend.description }}</span>
          <div class="custom-tooltip">
            {{ legend.information }}
          </div>
        </div>
      </div>
    </div>

    <!-- Contêiner da Tabela -->
    <div class="table-container" [ngStyle]="isDivVisible ? { height: '54vh' }: { height: '62vh' }">
      <!-- Estado de carregamento -->
      <div *ngIf="loading" class="loading-container">
        <div class="loading-spinner"></div>
        <p>Carregando dados...</p>
      </div>

      <!-- Estado de erro -->
      <div *ngIf="error" class="error-container">
        <p class="error-message">Ocorreu um erro ao carregar os dados.</p>
        <button class="retry-button" (click)="retryFetchData()">Tentar novamente</button>
      </div>

      <!-- Tabela -->
      <app-smart-table *ngIf="!loading && !error" [data]="data | async" [cards]="cards | async"
        [validators]="verificationImageTransformStrategy.validators()" [paginable]="true" [searchable]="true"
        [sortable]="false" [importable]="false" [exportable]="false" [submitable]="false" [preview]="false"
        [maxHeight]="8" [currentPage]="currentPage | async" [totalPages]="totalPages | async"
        [itemsPerPage]="itemsPerPage" [openModal]="openTicketPopup" (onPageChange)="handlePageChange($event)">
      </app-smart-table>
    </div>
  </div>
</div>