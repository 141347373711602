import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { SmartTableCard, SmartTableRow } from "src/app/components/smart-table/smart-table.component";
import { FilterService, Namespace } from "src/app/services/filter.service";
import { VerificationImageTransformStrategy } from "./strategy/VerificationImageTransformStrategy";
import { fadeInOutAnimation, slideInOutAnimation } from "src/app/animations/animations";
import { ImageVerificationService } from "src/app/services/image-verification.service";
import { TicketPopUpComponent } from "src/app/maps/ticket-pop-up/ticket-pop-up.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ZoomImagePopUpComponent } from "src/app/maps/zoom-image-pop-up/zoom-image-pop-up.component";
import { environment } from "src/environments/environment";
import { Formatter } from "src/app/utils/formatter.util";
import { GenerateDate } from "src/app/utils/generateDate.util";
import { icons } from "src/app/utils/icons";
import { defaultFilters, InputFilter } from "../interfaces/InputFilter";

interface Legendverification {
  description: string;
  value: string;
  information: string;
}

@Component({
  selector: 'app-verification-image',
  templateUrl: './verification-image.component.html',
  styleUrls: ['./verification-image.component.scss'],
  animations: [slideInOutAnimation, fadeInOutAnimation]
})
export class VerificationImageComponent implements OnInit {

  NAMESPACE: Namespace = 'imageVerification';

  data: BehaviorSubject<SmartTableRow[]> = new BehaviorSubject([]);
  legends: BehaviorSubject<Legendverification[]> = new BehaviorSubject([]);
  cards: BehaviorSubject<SmartTableCard[]> = new BehaviorSubject([]);
  selectedLegends: BehaviorSubject<string[]> = new BehaviorSubject([]);
  activeFilters: InputFilter = {};
  imagemUrl: string;
  loading: boolean = true;
  error: boolean = false;
  icons=icons
  currentPage = new BehaviorSubject<number>(1); // Página atual
  totalPages = new BehaviorSubject<number>(1); // Total de páginas
  itemsPerPage = 15
  dropdownFilters = new BehaviorSubject<Record<string, string[]>>(defaultFilters);
  selectedFilters = new BehaviorSubject<Record<string, string[]>>(defaultFilters);

  isDivVisible: boolean = false;

  constructor(
    private filterService: FilterService,
    private modalService: NgbModal,
    public verificationImageTransformStrategy: VerificationImageTransformStrategy,
    private imageVerificationService: ImageVerificationService,
    private cdr: ChangeDetectorRef
  ) {
    this.imagemUrl = environment.imagemUrl

    const { startDate, endDate } = GenerateDate.getDates();

    const defaultActiveFilters: InputFilter = {
      startDate: Formatter.formatDataPickerDate(startDate),
      endDate: Formatter.formatDataPickerDate(endDate),
      causes: '',
      validations: '',
      regionals: '',
      directors: '',
      states: '',
      clusters: '',
      subclusters: '',
      cities: ''
    }

    this.filterService.setDefaultFilters<InputFilter>({
      namespace: this.NAMESPACE,
      defaultFilters: defaultActiveFilters
    });

    this.filterService.getFiltersObservable<InputFilter>({
      namespace: this.NAMESPACE
    })
      .pipe(debounceTime(1000))
      .subscribe((activeFilters) => {
        this.activeFilters = activeFilters;
        this.fetchData(activeFilters, this.currentPage.value, this.itemsPerPage, null, null, null)
      });
  }

  cleanFilters() {
    const dropdownKeys = Object.keys(this.dropdownFilters.value);
    const selectedItems = dropdownKeys.reduce((a, c) => {
      a[c] = [];
      return a;
    }, {});
    
    selectedItems["causes"] = ['VANDALISMO'];
    this.selectedFilters.next({ ...selectedItems });
    this.filterService.updateMultipleFilters({ namespace: this.NAMESPACE, newFilters: {
      startDate: this.activeFilters.startDate,
      endDate: this.activeFilters.endDate,
      ...this.getDefaultFilters()
    } });
    this.cdr.detectChanges();
  }

  handlePageChange(event: { page: number, size: number, search: string, isPreview: boolean, sortBy: string[], sortDirection: string[]  }): void {
    const page = event.page || 1;
    this.fetchData(this.activeFilters, page, event.size, event.search, event.sortBy, event.sortDirection);
  }

  fetchData(activeFilters: InputFilter, page: number, size: number, search: string, sortBy: string[], sortDirection: string[] ) {
    this.loading = true;
    this.error = false;

    this.imageVerificationService
      .getVerificationImage(activeFilters, page, size, search, sortBy, sortDirection)
        .subscribe({
          next: ({ data, filters, cards, legends, totalPages, currentPage }) => {
            const transformedData = this.verificationImageTransformStrategy.transform(data);
            const treatedCards = cards.map((card) => ({ description: card.description, value: card.value, icon: icons[card.icon] }));
            this.data.next(transformedData);
            this.legends.next(legends);
            this.cards.next(treatedCards)
            this.totalPages.next(totalPages);
            this.currentPage.next(currentPage)
            this.dropdownFilters.next(filters);
            this.loading = false;
            this.error = false;
          },
          error: (err) => {
            this.loading = false;
            this.error = true;
          },
        });
  }

  ngOnInit(): void {}

  getLegendRows(legends: Legendverification[]): any[][] {
    let numRows = 4;
    let perRow = Math.ceil(legends.length / numRows);
    let rows = [];
    for (let i = 0; i < legends.length; i += perRow) {
      rows.push(legends.slice(i, i + perRow));
    }
    return rows;
  }

  isActive(legend: Legendverification): boolean {
    return this.selectedLegends.value.includes(legend.value);
  }

  toggleValidation(legend: Legendverification): void {
    const legends = new Set(this.selectedLegends.value);
    if (legends.has(legend.value)) {
      legends.delete(legend.value);
    } else {
      legends.add(legend.value);
    }
    this.selectedLegends.next(Array.from(legends));
    this.updateValidations();
  }

  updateValidations(): void {
    const legends = Array.from(this.selectedLegends.value)
      .map(legend => legend);
    const key = 'validations';
    const values = legends.join(',')
    this.onChange(`${key}:${values}`);
  }

  openHeaderFilter() {
    this.isDivVisible = !this.isDivVisible;
  }

  openTicketPopup = ({ rowId, value, field }: { rowId: string, field: string, value: any }) => {

    this.loading = true;

    if (field === 'ticketId') {
      const modalRef = this.modalService.open(TicketPopUpComponent, { size: 'lg' });
      modalRef.componentInstance.modalData = { ticketId: value, guid: rowId  };
      this.loading = false;
    }

    const validFields = ['f1', 'f2', 'f3', 'f4', 'f5'];

    if (validFields.includes(field)) {
      const dataItem = this.data.value.find(({ id }) => id === rowId);
      if (dataItem && dataItem.values && dataItem.values.length > 0) {
        const ticketId = dataItem.values[0].value;
        const url = `${this.imagemUrl}/fotosClaro/${ticketId}-${field[1]}.jpg`;
        const modalRef = this.modalService.open(ZoomImagePopUpComponent, { size: 'lg' });
        modalRef.componentInstance.modalData = { src: url };
        this.loading = false;
      }
    }
  }

  getRangeDate() {
    const { startDate, endDate } = GenerateDate.getYesterday();
    return { startDate, endDate };
  }

  getDefaultFilters(): InputFilter {
    return {
      causes: 'VANDALISMO',
      regionals: '',
      directors: '',
      states: '',
      clusters: '',
      subclusters: '',
      cities: ''
    }
  }

  // Função de onChange simplificada, agora lidando apenas com activeFilters
  onChange(event: string) {
    const [key, values] = event.split(':');
    this.filterService.updateFilters<InputFilter>({
      namespace: this.NAMESPACE,
      key,
      values,
    });
  }
}