<div>
  <div>
    <!-- * HEADER FILTER PAGE -->
    <div class="container-header-page" [class.bg-transition]="isDivVisible" #headerFilter>

      <div class="header-img-filter col-1">
        <img
          [src]="isDivVisible ? '../../../../assets/images/claro_logo_70.png' : '../../../../assets/images/claro_logo_50.png'"
          (click)="openHeaderFilter()" alt="logo da claro">
      </div>

      <div class="container-header-filters col-10" [@slideInOut]="isDivVisible ? 'in' : 'out'">
        <div class="container-filters col-12 p-0">

          <div class="col-lg-4 col-md-4">
            <app-data-picker-default [dates]="getRangeDate()" (event)="onChange($event)" [backgroundColor]="'#f9f9f9'"
              [color]="'#333333'">
            </app-data-picker-default>
          </div>

          <div class="col-2">
            <app-select-default key="causes" title="CAUSAS" [searchFilter]="true"
              [dropdownList]="dropdownFilters.value?.causes" (event)="onChange($event)"
              [selectedItems]="selectedFilters.value?.causes">
            </app-select-default>
          </div>

          <div class="col-2">
            <app-select-default key="families" title="FAMÍLIAS" [searchFilter]="true"
              [dropdownList]="dropdownFilters.value?.families" (event)="onChange($event)"
              [selectedItems]="selectedFilters.value?.families">
            </app-select-default>
          </div>

          <div class="col-2">
            <app-select-default key="regionals" title="REGIONAIS" [searchFilter]="true"
              [dropdownList]="dropdownFilters.value?.regionals" (event)="onChange($event)"
              [selectedItems]="selectedFilters.value?.regionals">
            </app-select-default>
          </div>

          <div class="col-2">
            <app-select-default key="directors" title="DIRETORIAS" [searchFilter]="true"
              [dropdownList]="dropdownFilters.value?.directors" (event)="onChange($event)"
              [selectedItems]="selectedFilters.value?.directors">
            </app-select-default>
          </div>

          <div class="col-2">
            <app-select-default key="states" title="ESTADOS" [searchFilter]="true"
              [dropdownList]="dropdownFilters.value?.states" (event)="onChange($event)"
              [selectedItems]="selectedFilters.value?.states">
            </app-select-default>
          </div>
        </div>

        <div class="container-filters col-12 p-0">
          <div class="col-2">
            <app-select-default key="clusters" title="CLUSTERS" [searchFilter]="true"
              [dropdownList]="dropdownFilters.value?.clusters" (event)="onChange($event)"
              [selectedItems]="selectedFilters.value?.clusters">
            </app-select-default>
          </div>

          <div class="col-2">
            <app-select-default key="subclusters" title="SUBCLUSTERS" [searchFilter]="true"
              [dropdownList]="dropdownFilters.value?.subclusters" (event)="onChange($event)"
              [selectedItems]="selectedFilters.value?.subclusters">
            </app-select-default>
          </div>

          <div class="col-2">
            <app-select-default key="cities" title="CIDADES" [searchFilter]="true"
              [dropdownList]="dropdownFilters.value?.cities" (event)="onChange($event)"
              [selectedItems]="selectedFilters.value?.cities">
            </app-select-default>
          </div>
        </div>
      </div>

      <div class="col-1" [@slideInOut]="isDivVisible ? 'in' : 'out'">
        <div class="col-2">
          <button class="clear-filters-button" (click)="cleanFilters()" matTooltip="Limpar filtros"
            aria-label="Limpar filtros">
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="row" style="margin: 1rem">

      <div class="views-container">
        <button class="view-button" (click)="setView('analyticalTicketsByDirectors')"
          [class.active]="view.value === 'analyticalTicketsByDirectors'">
          <i class="fa fa-building-o"></i> Atividades por Diretoria
        </button>

        <button class="view-button" (click)="setView('analyticalTicketsByTechnicians')"
          [class.active]="view.value === 'analyticalTicketsByTechnicians'">
          <i class="fa fa-cog"></i> Atividades por Técnico
        </button>

        <button class="view-button" (click)="setView('analyticalTicketsByMonths')"
          [class.active]="view.value === 'analyticalTicketsByMonths'">
          <i class="fa fa-calendar"></i> Atividades por Mês
        </button>

        <button class="view-button" (click)="setView('analyticalPhotosByValidations')"
          [class.active]="view.value === 'analyticalPhotosByValidations'">
          <i class="fa fa-check"></i> Fotos por Validação
        </button>
      </div>

      <div class="row">
        <div class="card-chart chart-full col-12 col-md-12 col-xxl-12"
          [ngStyle]="{'height': isDivVisible ? '56vh' : '63vh'}">

          <div class="card-chart-header chart-full-header" style="display: flex; flex-direction: row; padding: 1.5rem;">

            <div class="left-section">
              <button class="styled-button" (click)="openReportModal('worst')">
                <i class="fa fa-file"></i>
                <span>Relatório</span>
              </button>
            </div>

            <div class="center-section">
              <p class="chart-title">{{ data.value.title }}</p>
            </div>
          </div>

          <hr>

          <!-- Verifica se está carregando -->
          <div *ngIf="loading"
            class="loading-container d-flex flex-column justify-content-center align-items-center expand-max">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Carregando...</span>
            </div>
            <p class="loading-text mt-3">Estamos carregando os dados. Por favor, aguarde.</p>
          </div>

          <!-- Verifica se houve erro na requisição -->
          <div *ngIf="error && !loading"
            class="error-container d-flex flex-column justify-content-center align-items-center expand-max">
            <fa-icon [icon]="icons.danger" class="text-danger" size="3x"></fa-icon>
            <p class="error-text mt-3">Ocorreu um erro ao carregar os dados. Tente novamente mais tarde.</p>
          </div>

          <!-- Verifica se não encontrou o conteúdo -->
          <div *ngIf="!loading && !error && !isFoundContent"
            class="not-found-container d-flex flex-column justify-content-center align-items-center expand-max">
            <fa-icon [icon]="icons.search" class="text-muted" size="3x"></fa-icon>
            <p class="not-found-text mt-3">Nenhum conteúdo foi encontrado para os filtros aplicados.</p>
          </div>

          <div *ngIf="!loading && !error && isFoundContent" class="expand-max">
            <div class="legend-container">
              <ng-container *ngFor="let legend of data.value.legends; let i = index">
                <span class="legend-dot" [ngStyle]="{'background-color': colors[i] }"></span>
                <span class="legend-text">{{ legend }}</span>
              </ng-container>
            </div>

            <div style="height: 100%">
              <div class="d-flex chart-container" [ngStyle]="{ 'width': barWidthProportion }">
                <canvas baseChart height="90" width="100" [datasets]="barChartData.datasets"
                  [labels]="barChartData.labels" [options]="options" [legend]="false" [chartType]="'bar'">
                </canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>