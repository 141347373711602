import { Injectable } from '@angular/core';
import { format } from 'date-fns';
import { SmartTableRow } from 'src/app/components/smart-table/smart-table.component';
import { ExcelTransformStrategy, Permission } from 'src/app/interfaces';
import { Validators } from 'src/app/interfaces/Validators';

@Injectable()
export class RecurrenceTransformStrategy implements ExcelTransformStrategy {
  private readonly clusterOptions = ['RS', 'CO', 'NO', 'BA/SE', 'SPC', 'SPI', 'MG', 'NE', 'PR', 'RJ/ES', 'SC'];

  // Configuração de campos
  private readonly fieldConfig = [
    {
      column: 'TIPO',
      key: 'TIPO',
      field: 'type',
      defaultValue: '',
      requiredPermissionToUpdate: Permission.RECURRENCE_UPDATE_ADMIN,
      validators: [],
      sortable: true
    },
    {
      column: 'MÊS REF',
      key: 'MÊS REF',
      field: 'referenceDate',
      defaultValue: '',
      requiredPermissionToUpdate: Permission.RECURRENCE_UPDATE_ADMIN,
      validators: [Validators.isValidDate],
      transform: this.parseDate,
      sortable: true
    },
    {
      column: 'DIRETORIA',
      key: 'DIRETORIA',
      field: 'director',
      defaultValue: '',
      requiredPermissionToUpdate: Permission.RECURRENCE_UPDATE_ADMIN,
      validators: [value => Validators.isInOptions(value, this.clusterOptions)]
    },
    {
      column: 'DESIGNAÇÃO',
      key: 'DESIGNAÇÃO',
      field: 'designation',
      defaultValue: '',
      requiredPermissionToUpdate: null,
      validators: [Validators.notEmpty],
      readonly: true
    },
    {
      column: 'VOL',
      key: 'VOL',
      field: 'volume',
      defaultValue: '',
      requiredPermissionToUpdate: null,
      validators: [Validators.isNumber],
      readonly: true
    },
    {
      column: 'FATO',
      key: 'FATO',
      field: 'incident',
      defaultValue: '',
      requiredPermissionToUpdate: Permission.RECURRENCE_UPDATE,
      validators: []
    },
    {
      column: 'CAUSA',
      key: 'CAUSA',
      field: 'cause',
      defaultValue: '',
      requiredPermissionToUpdate: Permission.RECURRENCE_UPDATE,
      validators: []
    },
    {
      column: 'AÇÃO',
      key: 'AÇÃO',
      field: 'action',
      defaultValue: '',
      requiredPermissionToUpdate: Permission.RECURRENCE_UPDATE,
      validators: []
    },
    {
      column: 'PRAZO',
      key: 'PRAZO PARA CONCLUSÃO DO PLANO',
      field: 'deadline',
      defaultValue: '',
      requiredPermissionToUpdate: Permission.RECURRENCE_UPDATE,
      validators: []
    },
  ];

  // Método para obter validadores
  validators() {
    return this.fieldConfig.reduce((acc, field) => {
      acc[field.column] = field.validators || [];
      return acc;
    }, {});
  }

  // Método principal de transformação
  transform(sheetData: any[]): SmartTableRow[] {
    return sheetData.reduce((result: SmartTableRow[], row, rowIndex) => {
      const designation = row['DESIGNAÇÃO'];
      if (!designation || !Validators.notEmpty(designation)) {
        return result; // Ignora se a DESIGNAÇÃO é inválida
      }

      const values = this.fieldConfig.map((field, colIndex) => ({
        cellId: rowIndex * 100 + colIndex + 1,
        column: field.column,
        field: field.field,
        value: this.transformFieldValue(row[field.key], field),
        requiredPermissionToUpdate: field.requiredPermissionToUpdate,
        readonly: field.readonly,
        sortable: true
      }));

      result.push({
        id: designation,
        values,
      });

      return result;
    }, []);
  }

  // Transforma o valor de um campo com base na configuração
  private transformFieldValue(value: any, field: any): any {
    if (field.transform) {
      return field.transform(value);
    }
    return value ?? field.defaultValue;
  }

  // Método auxiliar para parse de datas
  private parseDate(date: number | string | undefined): string {
    if (typeof date === 'string') {
      return date;
    }

    if (typeof date === 'number') {
      const startDate = new Date(1900, 0, 1);
      const formatedDate = new Date(startDate.getTime() + (date - 2) * 24 * 60 * 60 * 1000);
      return format(formatedDate, 'dd/MM/yyyy');
    }

    return ''; // Retorna uma string vazia se o valor for inválido
  }
}
